import React from 'react'
import s from './Terms.module.scss'

const Terms = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <h1 className={s.title}>Terms & Conditions</h1>
        <h2 className={s.title}>
          Your Use of This Website is Governed By These Terms & Conditions
        </h2>
        <p className={s.description}>
          Please read the following Terms & Conditions carefully as they affect your
          legal rights. These Terms & Conditions contain an arbitration agreement that
          requires the use of arbitration on an individual basis to resolve disputes
          rather than jury or any other court proceedings, or class actions of any
          kind. The arbitration agreement is set forth in the “Arbitration Agreement”
          section below.
        </p>
      </div>
    </div>
  )
}

export default Terms
