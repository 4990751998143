import APIs from 'api'

import {
  setLoading,
  setOverview,
  setPlansList,
  setIsShowPlansPopup,
  setOpenPopupType,
  setAdminMembers
} from '../actions/subscription'

// Methods
// eslint-disable-next-line import/prefer-default-export
// export const getInitialDataActions = () => dispatch => {
//   dispatch(setLoading(true))

//   const body2 = { includes: ['features'] }
//   const body3 = { roles: ['Admin'], per_page: 10000 }

//   Promise.all([
//     APIs.getSubscriptionsOverview(),
//     APIs.subscriptionPlansList({ body: body2 }),
//     APIs.getMembers({ body: body3 })
//   ])
//     .then(res => {
//       // Overview response
//       const overview = {
//         ...res[0].data,
//         plan: {
//           ...res[0].data.plan,
//           plan_type: res[0].data.plan.plan_type.toLowerCase()
//         }
//       }
//       dispatch(setOverview(overview))

//       // Plans list response
//       const plansList = []
//       res[1].data.forEach(plans => {
//         const plan_type = plans.plan_type.toLowerCase()

//         plans.plans.forEach(plan => {
//           plansList.push({ ...plan, plan_type })
//         })
//       })
//       dispatch(setPlansList(plansList))

//       // Admin members response
//       const adminMembers = []
//       res[2].data.forEach(members => {
//         const { id, name, email } = members
//         adminMembers.push({ id, name, email })
//       })
//       dispatch(setAdminMembers(adminMembers))
//     })
//     .finally(() => dispatch(setLoading(false)))
// }
export const getInitialDataActions = () => async dispatch => {
  dispatch(setLoading(true))

  const body2 = { includes: ['features', 'prices'] }
  const body3 = { roles: ['Admin'], per_page: -1 } //the per_page must be at least 1

  try {
    const results = await Promise.allSettled([
      APIs.getSubscriptionsOverview(),
      APIs.subscriptionPlansList({ body: body2 }),
      APIs.getMembers({ body: body3 })
    ])

    // Overview response
    if (results[0].status === 'fulfilled') {
      const overview = {
        ...results[0].value.data,
        plan: {
          ...results[0].value.data.plan,
          plan_type: results[0].value.data.plan.plan_type.toLowerCase()
        }
      }
      dispatch(setOverview(overview))
    } else {
      console.error('Failed to fetch subscriptions overview:', results[0].reason)
      // Handle the error or dispatch an error action if needed
    }

    // Plans list response
    if (results[1].status === 'fulfilled') {
      const plansList = []
      results[1].value.data.forEach(plans => {
        const plan_type = plans.plan_type.toLowerCase()
        plans.plans.forEach(plan => {
          plansList.push({ ...plan, plan_type })
        })
      })
      dispatch(setPlansList(plansList))
    } else {
      console.error('Failed to fetch subscription plans list:', results[1].reason)
      // Handle the error or dispatch an error action if needed
    }

    // Admin members response
    if (results[2].status === 'fulfilled') {
      const adminMembers = []
      results[2].value.data.forEach(member => {
        const { id, name, email } = member
        adminMembers.push({ id, name, email })
      })
      dispatch(setAdminMembers(adminMembers))
    } else {
      console.error('Failed to fetch admin members:', results[2].reason)
      // Handle the error or dispatch an error action if needed
    }
  } catch (error) {
    console.error('An unexpected error occurred:', error)
  } finally {
    dispatch(setLoading(false))
  }
}
export const setIsShowPlansPopupAction = value => dispatch => {
  dispatch(setIsShowPlansPopup(value))
}

export const setOpenPopupTypeAction = value => dispatch => {
  dispatch(setOpenPopupType(value))
}
