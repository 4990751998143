/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import commaNumber from 'comma-number'
import Button from '@material-ui/core/Button'
import isEmpty from 'lodash.isempty'
import SnackManager from 'libs/snack'
import PropTypes from 'prop-types'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js'

import DropdownSearch from 'components/DropdownSearch'
import { useStore } from 'hooks'
import { CreditCard2, getCreditCardIcon } from 'components/Svg'

import Stripe from 'components/Svg/Icons/Stripe'
import Loading from 'components/Loading'
import APIs from 'api'
import { SUBSCRIPTION_PRICE } from 'api/constants/global'
import s from './PaymentForm.module.scss'
import StripeInput from './StripeInput'

const PaymentForm = ({
  isAnnually,
  inputValue,
  handlePayment,
  onChange,
  onCancel,
  onSubmit,
  isShowActions,
  showDetails,
  showButton,
  buttonText,
  currency,
  unitAmount,
  unitAmountYearly
}) => {
  const [loading, setLoading] = useState(false)

  const stripe = useStripe()
  const elements = useElements()
  const { state: globalState, getCountries } = useStore('GlobalContext')

  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    country: '',
    postalCode: '',
    cardType: null
  })

  const fields = [
    {
      key: 'number',
      size: 12,
      label: 'Card Number',
      placeholder: '',
      classes: { root: s.input },
      icon: getCreditCardIcon(state.cardType),
      type: 'input',
      id: 'cardNumber',
      props: {
        inputComponent: StripeInput,
        inputProps: {
          component: CardNumberElement
        }
      }
    },
    {
      key: 'date',
      size: 6,
      label: 'Month/Year',
      placeholder: 'MM / YY',
      classes: { root: s.input },
      icon: null,
      type: 'input',
      id: 'date',
      props: {
        inputComponent: StripeInput,
        inputProps: {
          component: CardExpiryElement
        }
      }
    },
    {
      key: 'code',
      size: 6,
      label: 'CVC',
      placeholder: '',
      classes: { root: s.input },
      icon: <CreditCard2 />,
      type: 'input',
      id: 'cvc',
      props: {
        inputComponent: StripeInput,
        inputProps: {
          component: CardCvcElement
        }
      }
    },
    {
      key: 'firstName',
      size: 6,
      label: 'First Name',
      placeholder: '',
      classes: { root: s.input },
      icon: null,
      type: 'input',
      id: 'firstName'
    },
    {
      key: 'lastName',
      size: 6,
      label: 'Last Name',
      placeholder: '',
      classes: { root: s.input },
      icon: null,
      type: 'input',
      id: 'lastName'
    },
    {
      key: 'country',
      size: 6,
      label: 'Country',
      placeholder: '',
      classes: { root: s.input },
      icon: null,
      type: 'dropdown',
      id: 'country'
    },
    {
      key: 'postalCode',
      size: 6,
      label: 'Postal Code',
      placeholder: '',
      classes: { root: s.input },
      icon: null,
      type: 'input',
      id: 'postalCode'
    }
  ]

  const months = isAnnually ? 12 : 1
  const baseCount = isAnnually ? (unitAmountYearly / 12).toFixed(2) : unitAmount
  const calcFactorAll = commaNumber((baseCount * inputValue * months).toFixed(2))
  const calcFactorText = `${baseCount} ${currency} x ${inputValue} Members x ${months} Month${
    months > 1 ? 's' : ''
  }`

  useEffect(() => {
    if (isEmpty(globalState.countries)) {
      getCountries()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.countries])

  const cardValidation = () => {
    const cardElementContainer = document.querySelector('#cardNumber')
    const dateElementContainer = document.querySelector('#date')
    const cvcElementContainer = document.querySelector('#cvc')
    const cardElementEmpty = cardElementContainer.classList.contains(
      'StripeElement--empty'
    )
    const dateElementEmpty = dateElementContainer.classList.contains(
      'StripeElement--empty'
    )
    const cvcElementEmpty = cvcElementContainer.classList.contains(
      'StripeElement--empty'
    )
    const cardElementInvalid = cardElementContainer.classList.contains(
      'StripeElement--invalid'
    )
    const dateElementInvalid = dateElementContainer.classList.contains(
      'StripeElement--invalid'
    )
    const cvcElementInvalid = cvcElementContainer.classList.contains(
      'StripeElement--invalid'
    )
    if (
      cardElementEmpty ||
      dateElementEmpty ||
      cvcElementEmpty ||
      cardElementInvalid ||
      dateElementInvalid ||
      cvcElementInvalid
    ) {
      SnackManager.error('Card information is invalid')
      return false
    }
    return true
  }

  const handleUpdatePlan = async () => {
    const isCardValid = cardValidation()
    if (
      state.cardType &&
      state.country &&
      state.firstName &&
      state.lastName &&
      state.postalCode &&
      isCardValid
    ) {
      setLoading(true)
      const secret = APIs.getClientSecret()

      const country = globalState.countries.find(item => item.name === state.country)
      const cardElement = elements.getElement(CardNumberElement)

      const customerinfo = {
        ...state,
        country: country.short_code
      }
      const billingDetails = {
        address: {
          country: country.short_code,
          postal_code: state.postalCode
        },
        email: null,
        name: `${state.firstName} ${state.lastName}`,
        phone: null
      }
      const getclientSecret = await secret.then(res => res)
      const paymentReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails
      })

      const payment = await stripe.confirmCardSetup(
        getclientSecret.data.client_secret,
        {
          payment_method: paymentReq.paymentMethod.id
        }
      )

      if (payment.error) {
        SnackManager.error(payment.error.message)
        setLoading(false)
      } else {
        handlePayment(payment.setupIntent.payment_method, customerinfo).finally(() =>
          setLoading(false)
        )
      }
    } else {
      SnackManager.error('please fill the form')
    }
  }

  const handleChange = event => {
    if (event.brand) {
      setState({ ...state, cardType: event.brand })
    } else if (event.target) {
      setState({ ...state, [event.target.name]: event.target.value })
    }
  }

  const handleSubmit = () => {
    if (isShowActions && onSubmit) onSubmit()
  }

  // eslint-disable-next-line no-unused-vars
  const handleCancel = () => {
    if (isShowActions && onCancel) onCancel()
  }

  return (
    <>
      <div className={s.cardWrapper}>
        <Grid container spacing={3}>
          <Stripe className={s.stripeIcon} />
          {fields.map(input => (
            <Grid item xs={input.size} key={input.key}>
              <div className={s.inputWrapper}>
                <span className={s.label}>{input.label}</span>
                {input.type === 'dropdown' ? (
                  <DropdownSearch
                    classes={{ root: s.dropdown }}
                    loading={globalState.loading.countries}
                    items={globalState.countries.map(({ id, name }) => ({
                      id,
                      title: name
                    }))}
                    multiple={false}
                    placeholder="Select a country"
                    onSelect={id => {
                      const country = globalState.countries.find(
                        item => item.id === id
                      )
                      setState(prevState => ({
                        ...prevState,
                        country: country ? country.name : ''
                      }))
                    }}
                    value={state.country}
                  />
                ) : (
                  <>
                    <div className={s.inputContainer}>
                      <TextField
                        // inputRef={el => {
                        //   inputsRef.current = {
                        //     ...inputsRef.current,
                        //     [input.key]: el
                        //   }
                        // }}
                        name={input.key}
                        classes={input.classes}
                        onChange={event => handleChange(event)}
                        // value={state[input.key]}
                        variant="outlined"
                        placeholder={input.placeholder}
                        InputProps={input.props}
                        id={input.id}
                      />

                      {input.icon}
                    </div>
                  </>
                )}
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      <div className={s.submit}>
        {showDetails ? (
          <div className={s.calcBox}>
            <p className={s.total}>
              Total:{'  '}
              <span className={s.num}>{calcFactorAll}</span>{' '}
              <span className={s.type}>{currency}</span>
            </p>
            <p className={s.calc}>{calcFactorText}</p>
          </div>
        ) : null}

        {showButton ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={onCancel}
            classes={{ root: s.btn, label: s.btnLabel }}
            className={s.cancel}
            data-testid="cancel"
          >
            cancel
          </Button>
        ) : null}

        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdatePlan}
          classes={{ root: s.btn, label: s.btnLabel }}
          disabled={loading}
          data-testid="submit"
        >
          {loading ? <Loading show className={s.loading} /> : buttonText}
        </Button>
      </div>
    </>
  )
}
PaymentForm.propTypes = {
  inputValue: PropTypes.number.isRequired,
  isAnnually: PropTypes.bool.isRequired,
  handlePayment: PropTypes.func.isRequired,
  isShowActions: PropTypes.bool,
  showDetails: PropTypes.bool,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func.isRequired
}

PaymentForm.defaultProps = {
  isShowActions: false,
  showButton: true,
  showDetails: true,
  buttonText: 'BUY',
  onChange: () => null,
  onSubmit: () => {}
}

export default PaymentForm
