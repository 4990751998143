import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'
import MaterialCheckbox from '@material-ui/core/Checkbox'

import s from './Checkbox.module.scss'

const Checkbox = ({ checked, onChange, value, color, checkedIconName, ...rest }) => {
  return (
    <MaterialCheckbox
      classes={{ root: s.root }}
      checked={checked}
      onChange={onChange}
      value={value}
      color={color}
      checkedIcon={
        <div className={cx(s.icon, s.checkedIcon)}>
          <div className={`icon-${checkedIconName}`} />
        </div>
      }
      icon={<div className={s.icon} />}
      {...rest}
    />
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  checkedIconName: PropTypes.string
}

Checkbox.defaultProps = {
  checkedIconName: 'Check'
}

export default Checkbox
