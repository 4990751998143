import {
  getInitialDateByType,
  getWeekDayDatesFromStart,
  getWeekNumber
} from 'libs/calendar'
import { toStandardDesignTime } from 'libs/utils'
import APIs from 'api'
import SnackManager from 'libs/snack'
import createDataContext from './createDataContext'

const getHours = seconds => seconds / 3600

const getTime = seconds => {
  if (seconds) {
    const t = toStandardDesignTime(getHours(seconds))
    const newT = []

    if (t[0] < 10) newT[0] = `0${t[0].toString()}`
    else newT[0] = t[0].toString()
    if (t[1] < 10) newT[1] = `0${t[1].toString()}`
    else newT[1] = t[1].toString()

    return `${newT[0]} ${newT[1]}`
  }

  return '00  00'
}

const createStandardProjectsData = ({ projects: pr, start_week }) => {
  const weekDates = getWeekDayDatesFromStart(start_week)

  const getProjectTimes = pt => {
    return Array(7)
      .fill(null)
      .map((nt, i) => ({
        billable_duration: '00 00',
        non_billable_duration: '00 00',
        duration_seconds: '00 00',
        date: weekDates[i],
        status_title: '',
        tags: []
      }))
      .map(nt => {
        let newNt = { ...nt }
        pt.forEach(ntt => {
          if (nt.date === ntt.date) {
            newNt = {
              duration_seconds: getTime(
                Number(ntt.billable_duration) + Number(ntt.non_billable_duration)
              ),
              non_billable_duration: getTime(ntt.non_billable_duration),
              billable_duration: getTime(ntt.billable_duration),
              date: ntt.date,
              tags: ntt.tags
            }
          }
        })
        return newNt
      })
  }

  const getOffTimes = ot => {
    return Array(7)
      .fill(null)
      .map((nt, i) => ({
        duration_seconds: '00 00',
        date: weekDates[i],
        status_title: '',
        tags: []
      }))
      .map(nt => {
        let newNt = { ...nt }
        ot.forEach(ott => {
          if (nt.date === ott.started_at.split(' ')[0]) {
            newNt = {
              duration_seconds: getTime(ott.duration_seconds),
              date: ott.started_at.split(' ')[0],
              status_title: ott.status_title,
              tags: [{ id: ott.time_off_tag_id, title: ott.tag_title }]
            }
          }
        })
        return newNt
      })
  }

  const getTagRowType = (pt, type = 'project') => {
    if (pt.length === 0) return 'per-week'

    if (type === 'project') {
      // eslint-disable-next-line
      if (
        pt.every(
          (val, _, arr) =>
            val.tags.map(({ id }) => id).toString() ===
            arr[0].tags.map(({ id }) => id).toString()
        )
      )
        return 'per-week'
      return 'per-day'
    }

    if (pt.every((val, _, arr) => val.time_off_tag_id === arr[0].time_off_tag_id))
      return 'per-week'
    return 'per-day'
  }

  const getWeekTagRow = (pt, type = 'project') => {
    if (pt.length === 0) return []

    if (type === 'project')
      return getTagRowType(pt, type) === 'per-week' ? pt[0].tags : []

    if (getTagRowType(pt, type) === 'per-week')
      return [{ id: pt[0].time_off_tag_id, title: pt[0].tag_title }]
    return []
  }

  const projectData = p => {
    if (p.source_type === 'project') {
      return {
        project_times: getProjectTimes(p.aggregated_project_times),
        tag_row_type: getTagRowType(p.aggregated_project_times),
        week_tags: getWeekTagRow(p.aggregated_project_times),
        source: p.source
      }
    }

    return {}
  }

  const offTimesData = p => {
    if (p.source_type === 'off_time_entry') {
      return {
        off_times: getOffTimes(p.off_times),
        tag_row_type: getTagRowType(p.off_times, p.source_type),
        week_tags: getWeekTagRow(p.off_times, p.source_type)
      }
    }

    return {}
  }

  return pr.map(p => ({
    row_id: p.id ? p.id : null,
    source_type: p.source_type,
    status_title: p.status_title,
    time_sheet_id: p.time_sheet_id,
    uuid: p.uuid,
    ...projectData(p),
    ...offTimesData(p)
  }))
}

const getDialogData = (type, start_week, end_week, member_name, memberName) => {
  // eslint-disable-next-line
  const title_simple =
    'Would you like to send an email to the members of the list to inform them about this decision?'
  // eslint-disable-next-line
  const title_name = `Would you like to send an email to ${memberName} to inform about this decision?`
  const subject_pending_approved = 'Your timesheet has been approved'
  const subject_pending_reject = 'Your timesheet has been declined'
  const subject_edit_accept = 'Your edit request has been accepted'
  const subject_edit_reject = 'Your edit request has been rejected'

  const getDialogMessagePending = isApprove => {
    const date = `${start_week} - ${end_week}`

    if (isApprove) {
      return `Your timesheet for (${date}) has been accepted by ${member_name}.

For further edits on the timesheet of this week, you need to send an edit request.`
    }
    return `Your timesheet for (${date}) has been declined by ${member_name}.

Please edit the timesheet and send it back for approval as soon as possible.`
  }

  const getDialogMessageEdit = isAccept => {
    const date = `${start_week} - ${end_week}`

    if (isAccept) {
      // eslint-disable-next-line max-len
      return `Your request to edit the timesheet for (${date}) has been accepted by ${member_name}.

Please edit the timesheet and send it back for approval as soon as possible.`
    }
    // eslint-disable-next-line max-len
    return `Your request to edit the timesheet for (${date}) has been rejected by ${member_name}.

Please contact ${member_name} for further information`
  }

  const data = { title: '', subject: '', message: '' }

  // Pending
  if (type.split('-')[0] === 'pending') {
    data.title = title_simple

    if (type === 'pending-approve' || type === 'pending-approve-all') {
      data.subject = subject_pending_approved
      data.message = getDialogMessagePending(true)
    } else {
      data.subject = subject_pending_reject
      data.message = getDialogMessagePending(false)
    }
  }

  // Edit request
  if (type.split('-')[0] === 'edit') {
    data.title = title_name

    if (type === 'edit-accept' || type === 'edit-accept-all') {
      data.subject = subject_edit_accept
      data.message = getDialogMessageEdit(true)
    } else {
      data.subject = subject_edit_reject
      data.message = getDialogMessageEdit(false)
    }
  }

  return data
}

const initialDate = getInitialDateByType('weekly')

const initialState = {
  member_id: null,
  member_name: null,
  table_cols: [],
  week_days: 7,
  step_mode: 'weekly',
  year: new Date().getFullYear().toString(),
  step_of_year: getWeekNumber(initialDate).toString(),
  start_week: initialDate[0],
  end_week: initialDate[1],
  tabValue: 0,
  sort_by: 'Logged hours',
  sort_type: 'desc', // or asc
  dialogData: null,
  dialogLoading: false,
  project_ids: [],
  project_role_ids: [],
  member_ids: [],
  timeApprovalList: [],
  patchRes: null
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'set_year_and_step':
      return {
        ...state,
        year: action.payload.year,
        step_of_year: action.payload.step_of_year,
        start_week: action.payload.start_week,
        end_week: action.payload.end_week,
        table_cols: action.payload.table_cols
      }

    case 'set_tab_value':
      return { ...state, tabValue: action.payload }

    case 'set_sort_by':
      return { ...state, sort_by: action.payload }

    case 'set_filter':
      return {
        ...state,
        project_ids: action.payload.projects,
        project_role_ids: action.payload.project_roles,
        member_ids: action.payload.members
      }

    case 'set_member_data':
      return {
        ...state,
        member_id: action.payload.id,
        member_name: action.payload.name
      }

    case 'set_time_approval_list':
      return { ...state, timeApprovalList: action.payload }

    case 'set_time_approval_details':
      return {
        ...state,
        timeApprovalList: state.timeApprovalList.map(tp => {
          const { uuid } = action.payload
          if (tp.uuid === uuid) return action.payload
          return tp
        })
      }

    case 'set_dialog_data':
      return { ...state, dialogData: action.payload }

    case 'set_dialog_loading':
      return { ...state, dialogLoading: action.payload }

    case 'set_patch_res':
      return { ...state, patchRes: action.payload }

    default:
      return state
  }
}

const setYearAndStep = dispatch => data => {
  const { year, step_of_year, start_week, end_week, table_cols } = data

  dispatch({
    type: 'set_year_and_step',
    payload: { year, step_of_year, start_week, end_week, table_cols }
  })
}

const setTabValue = dispatch => data => {
  dispatch({ type: 'set_tab_value', payload: data })
}

const setSortBy = dispatch => data => {
  dispatch({ type: 'set_sort_by', payload: data })
}

const setFilterData = dispatch => data => {
  dispatch({ type: 'set_filter', payload: data })
}

const setMemberData = dispatch => ({ id, name }) => {
  dispatch({ type: 'set_member_data', payload: { id, name } })
}

const setTimeApprovalList = dispatch => data => {
  dispatch({ type: 'set_time_approval_list', payload: data })
}

const setTimeApprovalDetails = dispatch => ({ item, start_week }) => {
  const params = { projects: item.time_sheet_rows, start_week }
  const time_sheet_rows = createStandardProjectsData(params)
  const newItem = { ...item, time_sheet_rows }

  dispatch({ type: 'set_time_approval_details', payload: newItem })
}

const approvedStatus = () => timeSheetRows => {
  let str = 'some'

  if (timeSheetRows.every(tsr => tsr.status_title === 'Approved')) str = 'all'

  if (timeSheetRows.every(tsr => tsr.status_title === 'Pending for approval'))
    str = ''

  return str
}

/*
 * type: pending-approve || pending-approve-all ||
 * pending-reject || pending-reject-all ||
 * edit-accept || edit-accept-all ||
 * edit-reject || edit-reject-all
 * rowType: all || single
 * uuid: timesheet uuid
 * rowId: if rowType === 'single' && project or time off id
 * state
 */
const handleShowDialog = (dispatch, state) => data => {
  const dispatchNull = () => dispatch({ type: 'set_dialog_data', payload: null })

  if (data) {
    const { type, rowType = 'single', uuid, rowId = null, memberName } = data
    const { start_week, end_week, member_name, timeApprovalList } = state

    const dispatchData = payload => dispatch({ type: 'set_dialog_data', payload })
    const dispatchDialogLoading = payload =>
      dispatch({ type: 'set_dialog_loading', payload }) // eslint-disable-line

    const dialogData = getDialogData(
      type,
      start_week,
      end_week,
      member_name,
      memberName
    ) // eslint-disable-line

    const responseFunc = payload => {
      dispatch({ type: 'set_patch_res', payload })
      SnackManager.success('Successfully done')
      dispatchNull()
    }

    const catchFunc = () => dispatchNull()

    const getTimeSheetMap = (tal, t, submitType, m) => {
      const id = t === 'TimeSheetRow' ? tal.row_id : tal.id
      const message = submitType === 'send' ? m : null

      return { attributes: { type: t }, id, message }
    }

    const apiCallByTypeAll = (message, submitType, endPoint) => {
      dispatchDialogLoading(true)

      const body = {
        allOrNone: true,
        records: timeApprovalList.map(tal =>
          getTimeSheetMap(tal, 'TimeSheet', submitType, message)
        )
      }

      APIs[endPoint]({ body })
        .then(responseFunc)
        .catch(catchFunc)
        .finally(() => dispatchDialogLoading(false))
    }

    const apiCallByTypeSingle = (message, submitType, endPoint) => {
      dispatchDialogLoading(true)

      const body = { allOrNone: true, records: [] }

      if (rowType === 'all') {
        body.records = timeApprovalList
          .filter(tal => tal.uuid === uuid)
          .map(tal => getTimeSheetMap(tal, 'TimeSheet', submitType, message))
      } else {
        body.records = timeApprovalList
          .filter(tal => tal.uuid === uuid)
          .map(({ time_sheet_rows }) => time_sheet_rows)[0]
          .filter(tal => tal.row_id === rowId)
          .map(tal => getTimeSheetMap(tal, 'TimeSheetRow', submitType, message))
      }

      APIs[endPoint]({ body })
        .then(responseFunc)
        .catch(catchFunc)
        .finally(() => dispatchDialogLoading(false))
    }

    switch (type) {
      case 'pending-approve-all':
        dispatchData({
          data: dialogData,
          callback: (message, submitType) =>
            apiCallByTypeAll(message, submitType, 'timeSheetApprove')
        })
        break

      case 'pending-reject-all':
        dispatchData({
          data: dialogData,
          callback: (message, submitType) =>
            apiCallByTypeAll(message, submitType, 'timeSheetDecline')
        })
        break

      case 'pending-approve':
        dispatchData({
          data: dialogData,
          callback: (message, submitType) =>
            apiCallByTypeSingle(message, submitType, 'timeSheetApprove')
        })
        break

      case 'pending-reject':
        dispatchData({
          data: dialogData,
          callback: (message, submitType) =>
            apiCallByTypeSingle(message, submitType, 'timeSheetDecline')
        })
        break

      case 'edit-accept-all':
        dispatchData({
          data: dialogData,
          callback: (message, submitType) =>
            apiCallByTypeAll(message, submitType, 'timeSheetEditAccept')
        })
        break

      case 'edit-reject-all':
        dispatchData({
          data: dialogData,
          callback: (message, submitType) =>
            apiCallByTypeAll(message, submitType, 'timeSheetEditReject')
        })
        break

      case 'edit-accept':
        dispatchData({
          data: dialogData,
          callback: (message, submitType) =>
            apiCallByTypeSingle(message, submitType, 'timeSheetEditAccept')
        })
        break

      case 'edit-reject':
        dispatchData({
          data: dialogData,
          callback: (message, submitType) =>
            apiCallByTypeSingle(message, submitType, 'timeSheetEditReject')
        })
        break

      default:
    }
  } else {
    dispatchNull()
  }
}

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    setYearAndStep,
    setTabValue,
    setSortBy,
    setFilterData,
    setMemberData,
    setTimeApprovalList,
    setTimeApprovalDetails,
    approvedStatus,
    handleShowDialog
  },
  initialState
)
