/* eslint-disable no-return-assign */
import React, { useRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'

const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef()
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus
  }))
  return <Component onReady={element => (elementRef.current = element)} {...props} />
}
StripeInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  component: PropTypes.func.isRequired
}

export default StripeInput
