import React from 'react'
import s from './PrivacyPolicy.module.scss'

const PrivacyPolicy = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <h1 className={s.title}>Privacy and Data Security Statement</h1>
        <p className={s.description}>
          This Privacy and Data Security Statement was last updated on November 7,
          2022. Thank you for visiting Route’s online and mobile resources and for
          viewing this privacy and data security statement. Our privacy statement
          serves to give notice about the types of personal information we collect,
          how and why we collect and use it, who we share it with and why, and what we
          do to store and protect it. We’ll outline those topics below and we
          encourage you to read each section carefully. For your convenience, we’ve
          also provided a quick summary below.
        </p>
        <h2 className={s.title}>Summary of How We Handle Personal Information</h2>
        <p className={s.description}>
          What kinds of personal information do we collect and hold? We collect and
          hold certain personal information from a variety of different data subjects
          including our workforce, vendors, merchant-customers, consumer-customers,
          and visitors to and users of our online and mobile resources. Click here to
          learn about the categories of personal information we collect from all four
          groups of data subjects. How and why do we use it? We use personal
          information received from visitors and users of our online and mobile
          resources to communicate directly with them. Click here for more information
          about how we use personal information. When / with whom do we share it and
          why? We share personal information when needed to fulfill our legal
          obligations and when our vendors, business partners, and affiliates need it
          to perform the contracts we have with them. We provide further detail about
          our sharing of personal information here. We do not sell or rent any
          personal information from any data subjects to third party data brokers or
          marketing companies. We may also share your information with certain
          overseas recipients. How do we store and protect it? We store personal
          information in paper-based files or other electronic record keeping methods.
          We’ve invested in a Security Program that addresses both technical and
          operational matters. Our program includes incident response and management
          and vendor oversight components. You can read about those components here.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
