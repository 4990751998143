// Types
export const SET_PAGE = 'SET_PAGE'
export const SET_LOADING = 'SET_LOADING'
export const SET_LAZY_LOADING = 'SET_LAZY_LOADING'
export const SET_PLANNING_MEMBERS = 'SET_PLANNING_MEMBERS'
export const SET_TOTAL_MEMBERS = 'SET_TOTAL_MEMBERS'
export const SET_API_PARAMS = 'SET_API_PARAMS'
export const SET_IS_ADD_MODAL_OPEN = 'SET_IS_ADD_MODAL_OPEN'
export const SET_ACTIVE_ROW = 'SET_ACTIVE_ROW'
export const SET_MODAL_SUBMITTED_TYPE = 'SET_MODAL_SUBMITTED_TYPE'
export const CLEAR_PLANNING_MEMBERS = 'CLEAR_PLANNING_MEMBERS'

// Creators
export const setPage = payload => ({ type: SET_PAGE, payload })

export const setLoading = payload => ({ type: SET_LOADING, payload })

export const setLazyLoading = payload => ({ type: SET_LAZY_LOADING, payload })

export const setPlanningMembers = payload => ({ type: SET_PLANNING_MEMBERS, payload })

export const clearPlanningMembers = () => ({ type: CLEAR_PLANNING_MEMBERS })

export const setTotalMembers = payload => ({ type: SET_TOTAL_MEMBERS, payload })

export const setApiParams = payload => ({ type: SET_API_PARAMS, payload })

export const setIsAddModalOpen = payload => ({ type: SET_IS_ADD_MODAL_OPEN, payload })

export const setActiveRow = payload => ({ type: SET_ACTIVE_ROW, payload })

export const setModalSubmittedType = payload => ({
  type: SET_MODAL_SUBMITTED_TYPE,
  payload
})
