// Types
export const SET_INVOICE_LOADING = 'SET_INVOICE_LOADING'
export const SET_CLIENT_ID = 'SET_CLIENT_ID'
export const SET_PROJECTS_LIST = 'SET_PROJECTS_LIST'
export const SET_PROJECTS_LIST_SINGLE = 'SET_PROJECTS_LIST_SINGLE'
export const SET_PROJECTS = 'SET_PROJECTS'
export const SET_VIEW_TYPE = 'SET_VIEW_TYPE'
export const SET_OTHER_ITEMS = 'SET_OTHER_ITEMS'
export const SET_INVOICE_FEE = 'SET_INVOICE_FEE'
export const SET_CLIENT_VAT_NUMBER = 'SET_CLIENT_VAT_NUMBER'
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS'
export const SET_COMPANY_INFO = 'SET_COMPANY_INFO'
export const SET_ATTACHMENT_IDS = 'SET_ATTACHMENT_IDS'
export const SET_INVOICE_DATE = 'SET_INVOICE_DATE'
export const SET_DUE_DATE = 'SET_DUE_DATE'
export const SET_INTEREST_RATE = 'SET_INTEREST_RATE'
export const SET_REMINDER_FEE = 'SET_REMINDER_FEE'
export const SET_REMINDERS = 'SET_REMINDERS'
export const SET_REMINDERS_CHECKED = 'SET_REMINDERS_CHECKED'
export const SET_DISCOUNT_TYPE = 'SET_DISCOUNT_TYPE'
export const SET_DISCOUNT = 'SET_DISCOUNT'
export const SET_DISCOUNT_CHECKED = 'SET_DISCOUNT_CHECKED'
export const SET_CHECK_RESULT = 'SET_CHECK_RESULT'
export const SET_CLIENT_NAME = 'SET_CLIENT_NAME'
export const SET_ALL = 'SET_ALL'
export const SET_INITIAL = 'SET_INITIAL'
export const SET_HAS_ERROR = 'SET_HAS_ERROR'
export const SET_UNINVOICED_DATA = 'SET_UNINVOICED_DATA'
export const SET_ATTACHED_DETAILED_REPORT = 'SET_ATTACHED_DETAILED_REPORT'
export const SET_IS_SEPARATE_DISCOUNT_CHANGED = 'SET_IS_SEPARATE_DISCOUNT_CHANGED'
export const SET_INVOICE_DETAIL_TYPE = 'SET_INVOICE_DETAIL_TYPE'

// Creators
export const setInvoiceLoading = payload => ({ type: SET_INVOICE_LOADING, payload })

export const setClientId = payload => ({ type: SET_CLIENT_ID, payload })

export const setProjectsList = payload => ({ type: SET_PROJECTS_LIST, payload })

export const setProjectsListSingle = payload => ({
  type: SET_PROJECTS_LIST_SINGLE,
  payload
})

export const setProjects = payload => ({ type: SET_PROJECTS, payload })

export const setViewType = payload => ({ type: SET_VIEW_TYPE, payload })

export const setInvoiceDetailType = payload => ({
  type: SET_INVOICE_DETAIL_TYPE,
  payload
})

export const setOtherItems = payload => ({ type: SET_OTHER_ITEMS, payload })

export const setInvoiceFee = payload => ({ type: SET_INVOICE_FEE, payload })

export const setClientVatNumber = payload => ({
  type: SET_CLIENT_VAT_NUMBER,
  payload
}) // eslint-disable-line

export const setPaymentMethods = payload => ({ type: SET_PAYMENT_METHODS, payload })

export const setCompanyInfo = payload => ({ type: SET_COMPANY_INFO, payload })

export const setAttachmentIds = payload => ({ type: SET_ATTACHMENT_IDS, payload })

export const setInvoiceDate = payload => ({ type: SET_INVOICE_DATE, payload })

export const setDueDate = payload => ({ type: SET_DUE_DATE, payload })

export const setInterestRate = payload => ({ type: SET_INTEREST_RATE, payload })

export const setReminderFee = payload => ({ type: SET_REMINDER_FEE, payload })

export const setReminders = payload => ({ type: SET_REMINDERS, payload })

export const setRemindersChecked = payload => ({
  type: SET_REMINDERS_CHECKED,
  payload
}) // eslint-disable-line

export const setDiscountType = payload => ({ type: SET_DISCOUNT_TYPE, payload })

export const setDiscount = payload => ({ type: SET_DISCOUNT, payload })

export const setDiscountChecked = payload => ({ type: SET_DISCOUNT_CHECKED, payload })

export const setCheckResult = payload => ({ type: SET_CHECK_RESULT, payload })

export const setAll = payload => ({ type: SET_ALL, payload })

export const setInitial = payload => ({ type: SET_INITIAL, payload })

export const setUnInvoicedData = payload => ({ type: SET_UNINVOICED_DATA, payload })

export const setClientName = payload => ({ type: SET_CLIENT_NAME, payload })

export const setHasErrorAction = payload => ({ type: SET_HAS_ERROR, payload })

export const setAttachedDetailedReport = payload => ({
  type: SET_ATTACHED_DETAILED_REPORT,
  payload
})
