import APIs from 'api'
import storage from 'libs/storage'
import { objWithoutProp } from 'libs/utils'
import { AUTH_TOKEN } from 'constants/app'

import createDataContext from './createDataContext'

const initialState = {
  loading: {
    getUserInfo: false,
    updateUserInfo: false,
    signup: false,
    confirmRegister: false,
    resendActivationCode: false,
    login: false,
    sendResetPasswordLink: false,
    checkPasswordResetToken: false,
    resetPassword: false
  },
  info: {}
}

const authReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'set_user_info':
      return { ...state, info: { ...state.info, ...payload } }

    case 'change_loading':
      return { ...state, loading: { ...state.loading, [payload.key]: payload.value } }

    default:
      return state
  }
}

const setLoading = dispatch => ({ key, value }) => {
  dispatch({
    type: 'change_loading',
    payload: { key, value }
  })
}

const deleteUserPhoto = () => uuid => {
  return new Promise((resolve, reject) => {
    APIs.deleteMedia({ params: { uuid } })
      .then(resolve)
      .catch(reject)
  })
}

const updateUserPassword = () => data => {
  return new Promise((resolve, reject) => {
    APIs.changeMyPassword({ body: data })
      .then(resolve)
      .catch(reject)
  })
}

const updateUserInfo = dispatch => ({ data, shouldTriggerLoading = true } = {}) => {
  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      if (!shouldTriggerLoading) return
      dispatch({
        type: 'change_loading',
        payload: { key: 'updateUserInfo', value }
      })
    }

    // If user deleted his/her photo, and wanted it to be empty,
    // do not send the 'avatar' parameter to API
    const getModifiedData = () => {
      const hasAvatar = Boolean(data.avatar)
      const body = { ...data }

      if (!hasAvatar) delete body.avatar

      return body
    }

    const body = getModifiedData()

    changeLoading(true)

    APIs.updateMyProfile({ body })
      .then(resolve)
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const checkUserPassword = dispatch => ({ checkPasswordValue }) => {
  const body = { password: checkPasswordValue }

  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'checkPassword', value }
      })
    }

    changeLoading(true)

    APIs.checkPassword({ body })
      .then(resolve)
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const changeEmail = dispatch => ({ newEmail }) => {
  const body = { email: newEmail }

  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'changeEmail', value }
      })
    }

    changeLoading(true)

    APIs.changeEmail({ body })
      .then(resolve)
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const verifyNewEmail = dispatch => (digit_code, newEmail) => {
  const body = {
    email: newEmail,
    digit_code
  }

  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'verifyNewEmail', value }
      })
    }

    changeLoading(true)

    APIs.verifyNewEmail({ body })
      .then(resolve)
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}
const getUserInfo = dispatch => ({ shouldTriggerLoading = true } = {}) => {
  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      if (!shouldTriggerLoading) return
      dispatch({
        type: 'change_loading',
        payload: { key: 'getUserInfo', value }
      })
    }

    changeLoading(true)
    APIs.getUserInfo()
      .then(data => {
        dispatch({ type: 'set_user_info', payload: data })
        resolve()
      })
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const login = dispatch => ({ email, password }) => {
  const body = { email, password }
  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'login', value }
      })
    }

    changeLoading(true)

    APIs.login({ body })
      .then(({ access_token }) => {
        // Save token in storage
        storage.save(AUTH_TOKEN, access_token)
        resolve()
      })
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const register = dispatch => data => {
  // If invite_token is null (signup_type is not 'Invite'),
  // do not send the key 'invite_token' to API.
  const body = data.invite_token ? data : objWithoutProp(data, 'invite_token')

  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'signup', value }
      })
    }

    changeLoading(true)
    APIs.signup({ body })
      .then(res => {
        const token = res.access_token

        // 'Invite' type only. In this case, user is already added as a member,
        // so it does not need confirmation
        if (token) {
          storage.save(AUTH_TOKEN, token)
          resolve({ needsConfirmation: false })
        } else {
          const { signup_type } = body
          const { email, name, is_verified, is_active } = res.data
          dispatch({
            type: 'set_user_info',
            payload: {
              email,
              name,
              signup_type,
              isVerified: is_verified,
              active: is_active
            }
          })
          resolve({ needsConfirmation: true })
        }
      })
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const resendActivationCode = (dispatch, state) => () => {
  const body = {
    email: state.info.email
  }

  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'resendActivationCode', value }
      })
    }

    changeLoading(true)

    APIs.resendActivationCode({ body })
      .then(resolve)
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const confirmRegister = (dispatch, state) => digit_code => {
  const body = {
    digit_code,
    email: state.info.email,
    signup_type: state.info.signup_type
  }

  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'confirmRegister', value }
      })
    }

    changeLoading(true)

    APIs.confirmSignup({ body })
      .then(({ access_token }) => {
        // Save token in storage
        storage.save(AUTH_TOKEN, access_token)

        resolve()
      })
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const checkUserVerification = () => invite_token => {
  return new Promise((resolve, reject) => {
    APIs.checkUserVerification({
      params: {
        invite_token
      }
    })
      .then(({ data: { email } }) => {
        resolve(email)
      })
      .catch(reject)
  })
}

const sendPasswordResetLink = dispatch => email => {
  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'sendResetPasswordLink', value }
      })
    }

    changeLoading(true)

    APIs.sendPasswordResetLink({ body: { email } })
      .then(resolve)
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const checkPasswordResetToken = dispatch => token => {
  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'checkPasswordResetToken', value }
      })
    }

    changeLoading(true)

    APIs.checkResetPasswordToken({ params: { token } })
      .then(({ email }) => {
        resolve(email)
      })
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const resetPassword = dispatch => ({
  email,
  password,
  password_confirmation,
  token
}) => {
  const body = { email, password, password_confirmation, token }

  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'resetPassword', value }
      })
    }

    changeLoading(true)

    APIs.resetPassword({ body })
      .then(resolve)
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}
const getUserProfile = dispatch => () => {
  return new Promise((resolve, reject) => {
    APIs.getProfile({ params: { 'includes[]': 'tempChosenFeatures' } })
      .then(res => {
        dispatch({ type: 'set_user_info', payload: res.data })
        resolve(res.data)
      })
      .catch(reject)
  })
}

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    login,
    getUserInfo,
    updateUserInfo,
    updateUserPassword,
    deleteUserPhoto,
    setLoading,
    register,
    resendActivationCode,
    confirmRegister,
    checkUserVerification,
    sendPasswordResetLink,
    checkPasswordResetToken,
    resetPassword,
    getUserProfile,
    checkUserPassword,
    changeEmail,
    verifyNewEmail
  },
  initialState
)
