import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'

import EMPTY_IMAGE from 'assets/images/empty.png'

import useTranslate from 'hooks/useTranslate'
import s from './Forbidden.module.scss'

function Forbidden({ classes }) {
  const t = useTranslate('general')
  return (
    <div className={cx(s.container, classes.root)}>
      <p className={cx(s.title, classes.title)}>{t('Forbidden')}</p>
      <img
        src={EMPTY_IMAGE}
        alt="Forbidden!"
        className={cx(s.image, classes.image)}
      />
    </div>
  )
}

Forbidden.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.string
  })
}

Forbidden.defaultProps = {
  classes: {
    root: '',
    title: '',
    image: ''
  }
}

export default Forbidden
