import { getInitialDateByType, getWeekNumber } from 'libs/calendar'
import {
  SET_YEAR_AND_STEP,
  SET_TAB_VALUE,
  SET_SORT_BY,
  SET_filter,
  SET_MEMBER_DATA,
  SET_TIME_APPROVAL_LIST,
  SET_TIME_APPROVAL_DETAILS,
  SET_DIALOG_DATA,
  SET_DIALOG_LOADING,
  SET_PATCH_RES
} from '../actions/timeApproval'

const initialDate = getInitialDateByType('weekly')

export const initialState = {
  member_id: null,
  member_name: null,
  table_cols: [],
  week_days: 7,
  step_mode: 'weekly',
  year: new Date().getFullYear().toString(),
  step_of_year: getWeekNumber(initialDate).toString(),
  start_week: initialDate[0],
  end_week: initialDate[1],
  tabValue: 0,
  sort_by: 'Logged hours',
  sort_type: 'desc', // or asc
  dialogData: null,
  dialogLoading: false,
  project_ids: [],
  project_role_ids: [],
  member_ids: [],
  timeApprovalList: [],
  patchRes: null
}

function timeApprovalReducer(state, action) {
  if (typeof state === 'undefined') return initialState

  switch (action.type) {
    case SET_YEAR_AND_STEP:
      return {
        ...state,
        year: action.payload.year,
        step_of_year: action.payload.step_of_year,
        start_week: action.payload.start_week,
        end_week: action.payload.end_week,
        table_cols: action.payload.table_cols
      }

    case SET_TAB_VALUE:
      return { ...state, tabValue: action.payload }

    case SET_SORT_BY:
      return { ...state, sort_by: action.payload }

    case SET_filter:
      return {
        ...state,
        project_ids: action.payload.projects,
        project_role_ids: action.payload.project_roles,
        member_ids: action.payload.members
      }

    case SET_MEMBER_DATA:
      return {
        ...state,
        member_id: action.payload.id,
        member_name: action.payload.name
      }

    case SET_TIME_APPROVAL_LIST:
      return { ...state, timeApprovalList: action.payload }

    case SET_TIME_APPROVAL_DETAILS:
      return {
        ...state,
        timeApprovalList: state.timeApprovalList.map(tp => {
          const { uuid } = action.payload
          if (tp.uuid === uuid) return action.payload
          return tp
        })
      }

    case SET_DIALOG_DATA:
      return { ...state, dialogData: action.payload }

    case SET_DIALOG_LOADING:
      return { ...state, dialogLoading: action.payload }

    case SET_PATCH_RES:
      return { ...state, patchRes: action.payload }

    default:
      return state
  }
}

export default timeApprovalReducer
