import { v4 as uuidv4 } from 'uuid'

import {
  getInitialDateByType,
  getWeekDayDatesFromStart,
  getWeekNumber
} from 'libs/calendar'
import { calculateTime, toStandardDesignTimeNew } from 'libs/utils'
import createDataContext from './createDataContext'

const getTime = seconds => {
  if (seconds) {
    const t = toStandardDesignTimeNew(calculateTime(seconds))
    const newT = []

    if (t[0] < 10) newT[0] = `0${t[0].toString()}`
    else newT[0] = t[0].toString()
    if (t[1] < 10) newT[1] = `0${t[1].toString()}`
    else newT[1] = t[1].toString()

    return `${newT[0]} ${newT[1]}`
  }

  return '00  00'
}

const getTimeOffObject = weekDates => {
  return {
    source_type: 'off_time_entry',
    status_title: 'Draft',
    time_sheet_id: null,
    uuid: uuidv4(),
    off_times: Array(7)
      .fill(null)
      .map((_, i) => ({
        duration_seconds: '00 00',
        date: weekDates[i],
        status_title: 'Draft',
        tags: []
      })),
    tag_row_type: 'per-week',
    week_tags: []
  }
}

const getTimeSheetSubmitStatus = projects => {
  let status = 'Send for approval'

  if (projects.length > 0) {
    if (projects.every(p => p.status_title === 'Pending for approval')) {
      status = 'Pending for approval'
    }

    if (projects.some(p => p.status_title === 'Approved')) {
      status = 'Partially approved'
    }

    if (projects.every(p => p.status_title === 'Approved')) {
      status = 'Approved'
    }

    if (projects.every(p => p.status_title === 'Approved and Invoiced')) {
      status = 'Approved and invoiced'
    }
  }

  return status
}

const createStandardProjectsData = ({ projects: pr, start_week, submit_type }) => {
  let projects = []

  const weekDates = getWeekDayDatesFromStart(start_week)

  const getProjectTimes = pt => {
    return Array(7)
      .fill(null)
      .map((nt, i) => ({
        billable_duration: '00 00',
        non_billable_duration: '00 00',
        duration_seconds: '00 00',
        date: weekDates[i],
        status_title: '',
        tags: []
      }))
      .map(nt => {
        let newNt = { ...nt }
        pt.forEach(ntt => {
          if (nt.date === ntt.date) {
            newNt = {
              duration_seconds: getTime(
                Number(ntt.billable_duration) + Number(ntt.non_billable_duration)
              ),
              non_billable_duration: getTime(ntt.non_billable_duration),
              billable_duration: getTime(ntt.billable_duration),
              date: ntt.date,
              tags: ntt.tags
            }
          }
        })
        return newNt
      })
  }

  const getOffTimes = ot => {
    return Array(7)
      .fill(null)
      .map((nt, i) => ({
        duration_seconds: '00 00',
        date: weekDates[i],
        status_title: '',
        tags: []
      }))
      .map(nt => {
        let newNt = { ...nt }
        ot.forEach(ott => {
          if (nt.date === ott.started_at.split(' ')[0]) {
            newNt = {
              duration_seconds: getTime(ott.duration_seconds),
              date: ott.started_at.split(' ')[0],
              status_title: ott.status_title,
              tags: [{ id: ott.time_off_tag_id, title: ott.tag_title }]
            }
          }
        })
        return newNt
      })
  }

  const getTagRowType = (pt, type = 'project') => {
    if (pt.length === 0) return 'per-week'

    if (type === 'project') {
      // eslint-disable-next-line
      if (
        pt.every(
          (val, _, arr) =>
            val.tags.map(({ id }) => id).toString() ===
            arr[0].tags.map(({ id }) => id).toString()
        )
      )
        return 'per-week'
      return 'per-day'
    }

    if (pt.every((val, _, arr) => val.time_off_tag_id === arr[0].time_off_tag_id))
      return 'per-week'
    return 'per-day'
  }

  const getWeekTagRow = (pt, type = 'project') => {
    if (pt.length === 0) return []

    if (type === 'project')
      return getTagRowType(pt, type) === 'per-week' ? pt[0].tags : []

    if (getTagRowType(pt, type) === 'per-week')
      return [{ id: pt[0].time_off_tag_id, title: pt[0].tag_title }]
    return []
  }

  const projectData = p => {
    if (p.source_type === 'project') {
      return {
        project_times: getProjectTimes(p.aggregated_project_times),
        tag_row_type: getTagRowType(p.aggregated_project_times),
        week_tags: getWeekTagRow(p.aggregated_project_times),
        source: p.source
      }
    }

    return {}
  }

  const offTimesData = p => {
    if (p.source_type === 'off_time_entry') {
      return {
        off_times: getOffTimes(p.off_times),
        tag_row_type: getTagRowType(p.off_times, p.source_type),
        week_tags: getWeekTagRow(p.off_times, p.source_type)
      }
    }

    return {}
  }

  if (submit_type) {
    projects = pr.map(p => ({
      row_id: p.id ? p.id : null,
      source_type: p.source_type,
      status_title: p.status_title,
      time_sheet_id: p.time_sheet_id,
      uuid: p.uuid,
      ...projectData(p),
      ...offTimesData(p)
    }))
  }

  return projects
}

const createEmptyProjectObjectFromArray = (projects, start_week) => {
  const weekDates = getWeekDayDatesFromStart(start_week)

  return projects.map(p => ({
    row_id: null,
    source_type: 'project',
    status_title: 'Draft',
    time_sheet_id: null,
    uuid: p.uuid,
    project_times: Array(7)
      .fill(null)
      .map((nt, i) => ({
        billable_duration: '00 00',
        non_billable_duration: '00 00',
        duration_seconds: '00 00',
        date: weekDates[i],
        status_title: 'Draft',
        tags: []
      })),
    tag_row_type: 'per-week',
    week_tags: [],
    source: {
      id: p.id,
      color: p.color,
      started_at: p.started_at,
      ended_at: p.ended_at,
      notes: p.notes,
      status: p.status,
      title: p.title,
      tags: p.tags,
      client: p.client
    }
  }))
}

// TimeSheet status:
//   1 => Draft
//   2 => Pending for approval
//   3 => Approved
// TimeSheetRows status:
//   1 => Draft
//   2 => Pending for approval
//   3 => Approved
//   4 => Pending edit request
//   5 => Approved and Invoiced

const initialDate = getInitialDateByType('weekly')

const initialState = {
  member_id: null,
  table_cols: [],
  week_days: 7,
  step_mode: 'weekly',
  time_sheet_id: null,
  year: new Date().getFullYear().toString(),
  step_of_year: getWeekNumber(initialDate).toString(),
  submit_type: 'post',
  send_to_approval: 1,
  confirm: 1,
  start_week: initialDate[0],
  end_week: initialDate[1],
  timesheet_data: null,
  timesheet_projects: [],
  initial_timesheet_projects: [],
  all_assigned_projects: [],
  weekly_planning: [],
  last_week_projects: [],
  deletedIds: [],
  // eslint-disable-next-line max-len
  timeSheetStatus: '' // types = Send for approval || Pending for approval || Partially approved || Approved || Approved and invoiced
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'set_year_and_step':
      return {
        ...state,
        year: action.payload.year,
        step_of_year: action.payload.step_of_year,
        start_week: action.payload.start_week,
        end_week: action.payload.end_week,
        table_cols: action.payload.table_cols,
        deletedIds: action.payload.deletedIds
      }

    case 'set_member_id':
      return { ...state, member_id: action.payload }

    case 'update_timesheet_projects':
      return {
        ...state,
        timesheet_projects: action.payload.projects,
        timeSheetStatus: action.payload.timeSheetStatus
      }

    case 'set_timesheet_projects':
      return {
        ...state,
        timesheet_projects: action.payload.projects,
        initial_timesheet_projects: action.payload.initialProjects,
        timesheet_data: action.payload.other
          ? action.payload.other
          : state.timesheet_data,
        submit_type: action.payload.submit_type,
        timeSheetStatus: action.payload.timeSheetStatus,
        time_sheet_id: action.payload.id
      }

    case 'set_timesheet_id':
      return { ...state, time_sheet_id: action.payload }

    case 'set_timesheet_status':
      return { ...state, timeSheetStatus: action.payload }

    case 'update_projects_with_new':
      return {
        ...state,
        timesheet_projects: [...state.timesheet_projects, ...action.payload.projects],
        timeSheetStatus: action.payload.timeSheetStatus
      }

    case 'set_all_Types_projects':
      return {
        ...state,
        all_assigned_projects: action.payload.all_assigned_projects,
        weekly_planning: action.payload.weekly_planning,
        last_week_projects: action.payload.last_week_projects
      }

    case 'add_time_off_to_projects':
      return {
        ...state,
        timesheet_projects: [...state.timesheet_projects, action.payload]
      }

    case 'update_timesheet_project_item':
      return {
        ...state,
        timesheet_projects: state.timesheet_projects.map(tp => {
          const { uuid } = action.payload.project
          if (tp.uuid === uuid) return action.payload.project
          return tp
        }),
        timeSheetStatus: action.payload.timeSheetStatus
      }

    case 'set_submit_type':
      return {
        ...state,
        timeSheetStatus: action.payload
      }

    case 'handle_add_deleted_row_id':
      return {
        ...state,
        deletedIds: !state.deletedIds.includes(action.payload)
          ? [...state.deletedIds, action.payload]
          : state.deletedIds
      }

    default:
      return state
  }
}

const setYearAndStep = dispatch => data => {
  const { year, step_of_year, start_week, end_week, table_cols, deletedIds } = data

  dispatch({
    type: 'set_year_and_step',
    payload: { year, step_of_year, start_week, end_week, table_cols, deletedIds }
  })
}

const setMemberId = dispatch => data => {
  dispatch({ type: 'set_member_id', payload: data })
}

const setTimeSheet = dispatch => data => {
  // eslint-disable-next-line
  const {
    projects: pr = [],
    other = null,
    start_week = null,
    submit_type = 'put',
    id = null
  } = data
  const params = { projects: pr, start_week, submit_type }

  const projects = createStandardProjectsData(params)
  const initialProjects = projects

  const timeSheetStatus = getTimeSheetSubmitStatus(projects)

  dispatch({
    type: 'set_timesheet_projects',
    payload: { projects, initialProjects, other, submit_type, timeSheetStatus, id }
  })
}

const setTimesheetId = dispatch => ({ id }) => {
  dispatch({ type: 'set_timesheet_id', payload: id })
}

const setSubmitType = dispatch => data => {
  dispatch({ type: 'set_submit_type', payload: data })
}

const setTimesheetStatus = dispatch => ({ status }) => {
  dispatch({ type: 'set_timesheet_status', payload: status })
}

const setTabsProjects = dispatch => (tab1 = [], tab2 = [], tab3 = []) => {
  dispatch({
    type: 'set_all_Types_projects',
    payload: {
      all_assigned_projects: tab1,
      weekly_planning: tab2,
      last_week_projects: tab3
    }
  })
}

// Update all projects
const updateTimeSheetProjects = dispatch => ({ projects }) => {
  // Set timeSheetStatus to `Send for Approval` with the first change
  const timeSheetStatus = 'Send for approval'

  dispatch({
    type: 'update_timesheet_projects',
    payload: { projects, timeSheetStatus }
  })
}

const handleAddDeletedRowId = dispatch => rowId => {
  dispatch({ type: 'handle_add_deleted_row_id', payload: rowId })
}

// Update one item
const updateTimeSheetProject = dispatch => ({ project }) => {
  // Set timeSheetStatus to `Send for Approval` with the first change
  const timeSheetStatus = 'Send for approval'

  dispatch({
    type: 'update_timesheet_project_item',
    payload: { project, timeSheetStatus }
  })
}

// Update timesheet projects from modal selected projects
const updateProjectsWithNew = dispatch => data => {
  // eslint-disable-next-line
  const { projects: pr, start_week, isFill, submit_type = 'put', tabValue = 0 } = data

  // Set timeSheetStatus to `Send for Approval` with the first change
  const timeSheetStatus = 'Send for approval'

  let newProjects = []

  if (isFill) {
    if (tabValue === 1 || tabValue === 2) {
      newProjects = createStandardProjectsData({
        projects: pr,
        start_week,
        submit_type
      })
    }
  } else {
    newProjects = createEmptyProjectObjectFromArray(pr, start_week)
  }

  dispatch({
    type: 'update_projects_with_new',
    payload: { projects: newProjects, timeSheetStatus }
  })
}

const addTimeOffToProjects = dispatch => ({ start_week }) => {
  const weekDates = getWeekDayDatesFromStart(start_week)
  const payload = getTimeOffObject(weekDates)

  dispatch({ type: 'add_time_off_to_projects', payload })
}

const getTimesheetPostData = () => state => {
  const getSecond = time => {
    const [hrs, min] = time.split(' ')
    return Number(hrs) * 3600 + Number(min) * 60
  }

  const time_sheet_rows = state.timesheet_projects
    .filter(
      row =>
        row.status_title === 'Draft' || row.status_title === 'Pending for approval'
    )
    .map(row => {
      const sameData = { source_type: row.source_type }

      if (row.row_id) sameData.id = row.row_id

      const filterEmptyTimes = time =>
        time.billable_duration > 0 || time.non_billable_duration > 0

      const filterEmptyTimeOffs = time => time.duration_seconds > 0

      const getTagsIds = tags => {
        if (row.tag_row_type === 'per-week') return row.week_tags.map(t => t.id)
        return tags.map(t => t.id)
      }

      if (row.source_type === 'project') {
        return {
          ...sameData,
          source_id: row.source.id,
          project_times: row.project_times
            .map(pt => ({
              date: pt.date,
              billable_duration: getSecond(pt.billable_duration),
              non_billable_duration: getSecond(pt.non_billable_duration),
              tag_id: getTagsIds(pt.tags)
            }))
            .filter(filterEmptyTimes)
        }
      }

      return {
        ...sameData,
        off_times: row.off_times
          .map(pt => ({
            date: pt.date,
            duration_seconds: getSecond(pt.duration_seconds),
            time_off_tag_id: getTagsIds(pt.tags)[0]
          }))
          .filter(filterEmptyTimeOffs)
      }
    })

  state.deletedIds.forEach(item => {
    const [id, source_type] = item.split('-')
    time_sheet_rows.push({ source_type, id: Number(id) })
  })

  return {
    year: Number(state.year),
    step_mode: state.step_mode,
    step_of_year: Number(state.step_of_year),
    send_to_approval: state.send_to_approval,
    confirm: state.confirm,
    time_sheet_rows
  }
}

const isDisableRow = () => status => {
  return (
    status === 'Approved' ||
    status === 'Approved and Invoiced' ||
    status === 'Pending edit request'
  )
}

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    setYearAndStep,
    setMemberId,
    setTimeSheet,
    updateTimeSheetProjects,
    handleAddDeletedRowId,
    updateTimeSheetProject,
    updateProjectsWithNew,
    setTabsProjects,
    setTimesheetId,
    setTimesheetStatus,
    addTimeOffToProjects,
    getTimesheetPostData,
    isDisableRow,
    setSubmitType
  },
  initialState
)
