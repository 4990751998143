import React, { useState, forwardRef, useImperativeHandle } from 'react'
import isEmpty from 'lodash.isempty'
import PropTypes from 'prop-types'
import Popover from '@material-ui/core/Popover'
import cx from 'clsx'

import Loading from 'components/Loading'

import s from './Dropdown.module.scss'

const Dropdown = forwardRef(
  (
    {
      classes,
      title,
      inputContent,
      placeholder,
      content,
      popoverAlign,
      disabled,
      loading,
      testid,
      isRequired,
      search,
      isListContent,
      onSelectedItemClick,
      selectedItem,
      extraBottomContent
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [searchText, setSearchText] = useState('')

    /**
     * handle search input change
     * @param {*} e | native event
     */
    const handleSearch = e => setSearchText(e.target.value)

    /**
     * clear search text
     */
    const clearSearch = () => setSearchText('')

    const handleClick = event => {
      if (disabled) return

      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => setAnchorEl(null)

    useImperativeHandle(ref, () => ({
      handleClose
    }))

    const open = Boolean(anchorEl)

    return (
      <div className={classes.wrapper}>
        <div className={cx(s.root, classes.root, { [s.disabled]: disabled })}>
          {title && (
            <div className={cx(s.titleContainer, classes.titleContainer)}>
              <span
                className={cx(
                  s.title,
                  disabled ? s.disabledTitle : '',
                  classes.title,
                  { [s.required]: isRequired }
                )}
              >
                {title}
              </span>
            </div>
          )}
          <button
            className={cx(s.inputContainer, classes.inputContainer)}
            type="button"
            onClick={handleClick}
            data-testid={testid}
          >
            <div className={cx(s.input, classes.input)}>
              <div className={cx(s.placeholder, classes.placeholder)}>
                {inputContent || placeholder}
              </div>
            </div>
            {!disabled && (
              <div className={cx(s.icon, classes.icon)}>
                <div className="icon-Cap-Down" />
              </div>
            )}
          </button>
        </div>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: popoverAlign.vertical,
            horizontal: popoverAlign.horizontal
          }}
          transformOrigin={{
            vertical: popoverAlign.vertical,
            horizontal: popoverAlign.horizontal
          }}
          classes={{ paper: cx(s.popover, classes.popover) }}
        >
          {loading ? (
            <Loading show className={s.loading} />
          ) : (
            <>
              {search && (
                <div className={s.searchBox}>
                  <div className={s.searchIcon}>
                    <div className="icon-Search" />
                  </div>
                  <input
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search"
                  />
                  {!isEmpty(searchText) && (
                    <button
                      className={s.clearSearch}
                      type="button"
                      onClick={clearSearch}
                    >
                      <div className="icon-Close" />
                    </button>
                  )}
                </div>
              )}
              {isListContent ? (
                <>
                  <div>
                    {content
                      .filter(item => {
                        if (isEmpty(searchText)) return item
                        return (
                          item.name
                            .toLowerCase()
                            .includes(searchText.toLocaleLowerCase()) || item.selected
                        )
                      })
                      .map((item, index) => {
                        const isSelected =
                          selectedItem && item.name === selectedItem.name
                        return (
                          <button
                            className={cx(s.item, isSelected ? s.selectedItem : '')}
                            key={`${item.name}-${index}`} // eslint-disable-line
                            type="button"
                            onClick={() => onSelectedItemClick(item)}
                            data-testid="dropdown-item"
                          >
                            <span>{item.name}</span>
                          </button>
                        )
                      })}
                  </div>
                  {extraBottomContent}
                </>
              ) : (
                <>
                  {content}
                  {extraBottomContent}
                </>
              )}
            </>
          )}
        </Popover>
      </div>
    )
  }
)

Dropdown.propTypes = {
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    root: PropTypes.string,
    titleContainer: PropTypes.string,
    title: PropTypes.string,
    inputContainer: PropTypes.string,
    input: PropTypes.string,
    icon: PropTypes.string,
    placeholder: PropTypes.string,
    popover: PropTypes.string
  }),
  title: PropTypes.string,
  inputContent: PropTypes.node,
  placeholder: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
  popoverAlign: PropTypes.shape({
    vertical: PropTypes.oneOf(['top', 'right', 'left', 'bottom', 'center']),
    horizontal: PropTypes.oneOf(['top', 'right', 'left', 'bottom', 'center'])
  }),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  testid: PropTypes.string,
  isRequired: PropTypes.bool,
  search: PropTypes.bool,
  isListContent: PropTypes.bool,
  onSelectedItemClick: PropTypes.func,
  selectedItem: PropTypes.object, // eslint-disable-line
  extraBottomContent: PropTypes.node // eslint-disable-line
}

Dropdown.defaultProps = {
  classes: {
    wrapper: '',
    root: '',
    titleContainer: '',
    title: '',
    inputContainer: '',
    input: '',
    icon: '',
    placeholder: '',
    popover: ''
  },
  title: null,
  inputContent: null,
  placeholder: '',
  popoverAlign: {
    vertical: 'top',
    horizontal: 'center'
  },
  disabled: false,
  loading: false,
  testid: '',
  isRequired: false,
  search: false,
  isListContent: false,
  onSelectedItemClick: () => {},
  selectedItem: null,
  extraBottomContent: null
}

export default Dropdown
