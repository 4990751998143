import Empty from 'components/Empty'
import React from 'react'
import s from './NotFound.module.scss'

function index() {
  return (
    <div className={s.wrapper}>
      <Empty />
    </div>
  )
}

export default index
