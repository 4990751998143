import React from 'react'
import { useLocation } from 'react-router-dom'
import Forbidden from 'components/Forbidden'
import s from './Forbidden.module.scss'

function Forbid() {
  const location = useLocation()
  location.state = { redirectUrl: '/dashboard' }
  return (
    <div className={s.wrapper}>
      <Forbidden />
    </div>
  )
}

export default Forbid
