import React from 'react'
import ReactDOM from 'react-dom'

import App from 'routes'
import 'theme/index.scss'
import 'i18n'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <React.Suspense fallback=" ">
    <App />
  </React.Suspense>,
  document.getElementById('root')
)

serviceWorker.unregister()
