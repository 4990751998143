/* eslint-disable prefer-template */
import queryString from 'query-string'
import { v4 as uuidv4 } from 'uuid'

import { MONTH_DAYS, WEEK_DAYS } from 'constants/app'
import { HOSTNAME, PROTOCOL } from 'api/constants/global'
import { t } from 'i18next'

/**
 * Trims/clips an string
 *
 * @param {string} str - input string.
 * @param {number} limit - defines how many characters should be taken.
 * @param {boolean} addDots - if true, appends ... to the end of trimmed string.
 */
const trimString = (str, limit = 15, addDots = true) => {
  if (!str) return ''

  let newStr = str
  if (str.length > limit) {
    // trim the string
    newStr = str.substr(0, limit)

    // re-trim if we are in the middle of a word
    // newStr = newStr.substr(0, Math.min(newStr.length, newStr.lastIndexOf(' ')))

    // add dot at the end of string
    newStr = addDots ? `${newStr}...` : newStr
  }
  return newStr
}

/**
 * Converts am object into query string.
 * @param {object} data - input object that will be transformed into query string.
 */
const serializer = data => {
  // If the data is already a string, return it as-is
  if (typeof data === 'string') return data

  // Create a query array to hold the key/value pairs
  const query = []

  // Loop through the data object
  Object.keys(data).forEach(key => {
    const value = data[key]
    if (value) {
      if (value.constructor === Array) {
        value.forEach(val => {
          query.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
        })
      } else {
        // Encode each key and value, concatenate them into a string,
        // and push them to the array
        query.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }
    }
  })

  // Join each item in the array with a `&` and return the resulting string
  return query.join('&')
}

/**
 * Replaces a substring of an string with new characters.
 * @param {string} $old - old substring
 * @param {string} $new - new substring
 * @param {string} $text - whole string
 */
const strReplace = ($text, $old, $new) => $text.split($old).join($new)

const strReplaceByIndex = ($text, $index, $new) => {
  return `${$text.substr(0, $index)}${$new}${$text.substr($index + 1)}`
}

const normalize = (size, unit) => {
  const unitBaseSize = unit === 'vw' || unit === 'vmax' ? 1440 : 900

  return `${(size / unitBaseSize) * 100}${unit}`
}

const boldString = (str, find) => {
  let newStr = str

  // default
  newStr = newStr.replace(find, `<b>${find}</b>`)

  // upper
  const upper = find.toUpperCase()
  newStr = newStr.replace(upper, `<b>${upper}</b>`)

  return newStr
}

const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = Number.parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  // eslint-disable-next-line no-restricted-properties
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`
}

const dateFormatted = d => {
  const year = d.getFullYear()
  const date = d.getDate()
  const monthName = MONTH_DAYS[d.getMonth()].slice(0, 3)

  return `${monthName} ${date}, ${year}`
}

const dateFormattedWeek = d => {
  const year = d.getFullYear()
  const date = d.getDate()
  const monthName = MONTH_DAYS[d.getMonth()].slice(0, 3)
  const dayName = WEEK_DAYS[d.getDay()]

  return `${t(`general.date.days.${dayName}`)}, ${date} ${t(
    `general.date.months.${monthName}`
  )} ${year}`
}

const objectToQueryStringWithType = (params, arrayFormat = 'index') => {
  const qs = `${queryString.stringify(params, { arrayFormat })}`
    .split('&') // split with & to array
    .filter(it => it.includes('=')) // filter empty(null) values of arrays
    .join('&') // to query string

  return `?${qs}`
}

const toStandardDesignTime = time => {
  const calculatedTime = time.toFixed(3)
  const TimeArr = String(calculatedTime).split('.')

  const isHourMoreThanTwoDigits = Boolean(TimeArr[0].length > 2)

  const hrs = TimeArr[0] ? TimeArr[0] : '0'
  let min = TimeArr[1] ? TimeArr[1] : '0'

  if (min.length === 1) min *= 10

  const roundedMinutes = Math.ceil((Number(String(min).substring(0, 2)) / 100) * 60)

  return [
    Number(String(hrs).substring(0, isHourMoreThanTwoDigits ? 3 : 2)),
    roundedMinutes
  ]
}

const toStandardDesignTimeNew = time => {
  const TimeArr = String(time).split('.')
  let hr = Number(TimeArr[0])
  let min = TimeArr[1] ? Number(TimeArr[1]) : 0

  if (TimeArr.length > 1) {
    if (TimeArr[1] > 59) {
      min = Number(TimeArr[1]) - 60
      hr = Number(TimeArr[0]) + 1
    }

    return [hr, min]
  }

  return [hr, 0]
}

const calculateTime = secs => {
  const ms = Math.abs(secs) * 1000 // Convert to positive milliseconds
  const minutes = Math.floor((ms / (1000 * 60)) % 60)
  const hours = Math.floor(ms / (1000 * 60 * 60))

  const sign = secs < 0 ? '-' : '' // Determine the sign of the time

  // hours = hours < 10 ? '0' + hours : hours
  // minutes = minutes < 10 ? '0' + minutes : minutes

  return sign + hours + '.' + sign + minutes
}

/**
 * Discount calculator with percent and amount
 * @param type - 0 = amount, 1 = percent
 * @param value
 * @param amount - initial value
 * @returns {number|string}
 */
const discountCalculator = (type, value, amount) => {
  if (type === 1) {
    if (value >= 100) return 0

    if (value === 0) return amount

    return Number((amount - (amount * value) / 100).toFixed(2))
  }

  return amount - value
}

const addUuidToArrayOfObjects = arr => {
  const newArr = [...arr]
  return newArr.map(a => ({ ...a, uuid: uuidv4() }))
}

const createAutoDownloadLink = (fileURL, fileName) => {
  const a = document.createElement('a')

  a.href = fileURL
  a.download = fileName
  a.click()

  window.URL.revokeObjectURL(fileURL)
}

const downloadPdfFromBlob = (blobData, fileName) => {
  const file = new Blob([blobData], { type: 'application/pdf' })
  const fileURL = URL.createObjectURL(file)
  createAutoDownloadLink(fileURL, fileName)
}

const downloadCsvFromBlob = (blobData, fileName) => {
  blobData.text().then(text => {
    const csvFile = new Blob([text], { type: 'text/csv; charset=utf-8' })
    const fileURL = URL.createObjectURL(csvFile)
    createAutoDownloadLink(fileURL, fileName)
  })
}

const downloadXlsxFromBlob = (blobData, fileName) => {
  const csvFile = new Blob([blobData], { type: 'text/csv; charset=utf-8' })
  const fileURL = URL.createObjectURL(csvFile)
  createAutoDownloadLink(fileURL, fileName)
}

const isFloat = n => Number(n) === n && n % 1 !== 0

const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1)

const getFileInputImageSrc = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = processedFile => {
      resolve(processedFile.target.result)
    }

    fileReader.onerror = reject
  })
}

// const prependImageBaseUrlToString = src => `${src}`

function prependImageBaseUrlToString(src) {
  const baseUrl = 'https://api.dev.bisflow.com'
  if (src.startsWith('http')) {
    return src
  } else {
    return `${baseUrl}${src}`
  }
}

const deepClone = value => JSON.parse(JSON.stringify(value))

const objWithoutProp = (obj, unwantedProp) => {
  const newObj = { ...obj }

  delete newObj[unwantedProp]
  return newObj
}

const uniqueArray = arr => {
  const uniqueArr = arr.filter((value, index, self) => self.indexOf(value) === index)
  return uniqueArr
}

const memoize = fn => {
  const cache = {}

  return arg => {
    if (arg in cache) return cache[arg]

    const result = fn(arg)
    cache[arg] = result
    return result
  }
}

const getInRange = (value, { min, max }) => {
  if (value <= min) return min
  if (value >= max) return max

  return value
}

/**
 * @returns {Array} intersection of two arrays
 */

const getArrayIntersection = (array1, array2) => {
  return array1.filter(value => array2.includes(value))
}

const removeLastArrayElement = array => {
  if (array.length <= 1) return []

  return array.slice(0, array.length - 1)
}

const includesDigits = str => {
  try {
    const digits = str.match(/\d/g)
    return digits.length !== 0
  } catch (err) {
    return false
  }
}

export {
  trimString,
  serializer,
  strReplace,
  strReplaceByIndex,
  normalize,
  boldString,
  bytesToSize,
  dateFormatted,
  dateFormattedWeek,
  toStandardDesignTime,
  toStandardDesignTimeNew,
  calculateTime,
  objectToQueryStringWithType,
  discountCalculator,
  addUuidToArrayOfObjects,
  downloadPdfFromBlob,
  downloadCsvFromBlob,
  downloadXlsxFromBlob,
  isFloat,
  capitalizeFirstLetter,
  getFileInputImageSrc,
  prependImageBaseUrlToString,
  deepClone,
  objWithoutProp,
  uniqueArray,
  memoize,
  getArrayIntersection,
  getInRange,
  removeLastArrayElement,
  includesDigits
}
