import APIs from 'api'

import createDataContext from './createDataContext'

const initialState = {
  loading: {
    countries: false,
    timezones: false
  },
  permissions: {
    regular: [],
    project: [],
    team: []
  },
  timezones: {},
  countries: [],
  companyInfo: {}
}

const authReducer = (state, action) => {
  const { type, payload } = action
  switch (type) {
    case 'change_loading':
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.key]: payload.value
        }
      }
    case 'set_regular_permissions':
      return {
        ...state,
        permissions: {
          ...state.permissions,
          regular: payload
        }
      }
    case 'set_project_permissions':
      return {
        ...state,
        permissions: {
          ...state.permissions,
          project: payload
        }
      }
    case 'set_team_permissions':
      return {
        ...state,
        permissions: {
          ...state.permissions,
          team: payload
        }
      }

    case 'set_timezones':
      return { ...state, timezones: payload }

    case 'set_countries':
      return {
        ...state,
        countries: payload
      }
    case 'set_company_info':
      return {
        ...state,
        companyInfo: payload
      }
    default:
      return state
  }
}

const getRegularPermissions = dispatch => () => {
  return new Promise((resolve, reject) => {
    APIs.getRegularPermissions()
      .then(({ data }) => {
        dispatch({
          type: 'set_regular_permissions',
          payload: data
        })
        resolve()
      })
      .catch(reject)
  })
}

const getProjectPermissions = dispatch => () => {
  return new Promise((resolve, reject) => {
    APIs.getProjectPermissions()
      .then(({ data }) => {
        dispatch({
          type: 'set_project_permissions',
          payload: data
        })
        resolve()
      })
      .catch(reject)
  })
}

const getTeamPermissions = dispatch => () => {
  return new Promise((resolve, reject) => {
    APIs.getTeamPermissions()
      .then(({ data }) => {
        dispatch({
          type: 'set_team_permissions',
          payload: data
        })
        resolve()
      })
      .catch(reject)
  })
}

const getTimezones = dispatch => () => {
  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'timezones', value }
      })
    }

    changeLoading(true)

    APIs.getTimezones()
      .then(({ data }) => {
        dispatch({ type: 'set_timezones', payload: data })
        resolve()
      })
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const getCountries = dispatch => () => {
  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'countries', value }
      })
    }

    changeLoading(true)

    APIs.getCountries()
      .then(({ data }) => {
        dispatch({
          type: 'set_countries',
          payload: data
        })
        resolve()
      })
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

const getCompanyInfo = dispatch => () => {
  return new Promise((resolve, reject) => {
    const changeLoading = value => {
      dispatch({
        type: 'change_loading',
        payload: { key: 'companyInfo', value }
      })
    }

    changeLoading(true)

    APIs.getCompanyDetails({
      body: { includes: ['addresses', 'paymentMethods', 'defaultAddress'] }
    })
      .then(({ data }) => {
        dispatch({
          type: 'set_company_info',
          payload: data
        })
        resolve()
      })
      .catch(reject)
      .finally(() => {
        changeLoading(false)
      })
  })
}

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    getRegularPermissions,
    getProjectPermissions,
    getTeamPermissions,
    getTimezones,
    getCountries,
    getCompanyInfo
  },
  initialState
)
