/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import cx from 'clsx'
import Button from '@material-ui/core/Button'
import PlanRenewal from '../PlanRenewal'
import Modal from 'components/Modal'
import useTranslate from 'hooks/useTranslate'
import s from './ReadOnly.module.scss'

function ReadOnly({ readOnly, userRole, isUserFreezed, isUserLoggedIn, planId }) {
  const [showModal, setShowModal] = useState(false)
  const overview = useSelector(state => state.subscription.overview)
  const tenantOwner = overview?.tenant_name
  const tenantOwnerEmail = overview?.tenant_email
  const defaultPaymentMethod = overview?.default_payment_method
  const selectedCurrency = overview?.plan?.selected_price
  const usedQuantity = overview?.used_quantity

  const t = useTranslate('dashboard.readOnly')
  const isOwner = userRole === 'owner'

  const handleUpdatePlan = () => {
    setShowModal(true)
  }
  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <div
      className={cx(
        readOnly && isUserLoggedIn && tenantOwner ? s.readOnly : s.readOnlyHidden
      )}
    >
      <div className={s.textsContainer}>
        <div className={s.alertIcon}>
          <div className="icon-Alert-Outlined" />
        </div>

        <div className={s.texts}>
          <p style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              {planId === 4 && (isUserFreezed || readOnly)
                ? t('4')
                : isUserFreezed
                ? t('3')
                : t('0')}
            </span>
            {isOwner ? (
              <span>{planId === 4 ? t('5') : t('1')}. </span>
            ) : (
              <span>
                {t('2')} {tenantOwner} ({tenantOwnerEmail})
              </span>
            )}
          </p>
        </div>
      </div>
      {isOwner && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdatePlan}
          classes={{ root: s.btn, label: s.btnLabel }}
          // disabled={loading}
          data-testid="submit"
        >
          Renew your subscription
        </Button>
      )}

      <Modal
        open={showModal}
        onClose={handleClose}
        classes={{
          modal: s.modal,
          paper: s.paper
        }}
      >
        <PlanRenewal
          defaultPaymentMethod={defaultPaymentMethod}
          selectedCurrency={selectedCurrency}
          usedQuantity={usedQuantity}
          handleClose={handleClose}
        />
      </Modal>
    </div>
  )
}

export default ReadOnly
