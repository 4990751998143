import React from 'react'
import s from './CookiePolicy.module.scss'

const CookiePolicy = () => {
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <h1 className={s.title}>Cookie Policy of www.bisflow.com</h1>
        <h2 className={s.title}>Your Privacy Choices</h2>
        <p className={s.description}>
          In this panel you can express some preferences related to the processing of
          your personal information. You may review and change expressed choices at
          any time by resurfacing this panel via the provided link. To deny your
          consent to the specific processing activities described below, switch the
          toggles to off or use the “Reject all” button and confirm you want to save
          your choices.
        </p>
      </div>
    </div>
  )
}

export default CookiePolicy
